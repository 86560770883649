import React from 'react';
import Header from '../Header';
import MainContent from '../MainContent';
import Footer from '../Footer';

const Home = () => {
  return (
    <>
      <Header />
      <MainContent />
      <Footer />
    </>
  );
};

export default Home;
