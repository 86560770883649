import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  return (
    <nav className="navbar p-4 navbar-expand-sm d-flex justify-content-center">
      <ul className="navbar-nav text-center">
        <li className="nav-item">
          <Link to="/home" className="btn btn-lg custom_btn">home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="btn btn-lg custom_btn">about</Link>
        </li>
        <li className="nav-item">
          <Link to="/music" className="btn btn-lg custom_btn">music</Link>
        </li>
        <li className="nav-item">
          <Link to="/mtjlc" className="btn btn-lg custom_btn">mtjLC</Link>
        </li>
        <li className="nav-item">
          <Link to="/codes" className="btn btn-lg custom_btn">codes</Link>
        </li>
        <li className="nav-item">
          <Link to="/events" className="btn btn-lg custom_btn">events</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="btn btn-lg custom_btn">contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
