// ConstructionMessage.js

import React, { useEffect } from 'react';
import '../css/ConstructionMessage.css'; // Import the CSS file for custom styles

const ConstructionMessage = () => {
  useEffect(() => {
    // Function to update the message position randomly
    const updatePosition = () => {
      const message = document.querySelector('.construction-message');
      if (message) {
        const x = Math.random() * (window.innerWidth - message.offsetWidth);
        const y = Math.random() * (window.innerHeight - message.offsetHeight);
        message.style.left = `${x}px`;
        message.style.top = `${y}px`;
      }
    };

    // Update the message position every 3 seconds (adjust the interval as needed)
    const interval = setInterval(updatePosition, 2000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="construction-message rounded shadow">
      This site is coming soon!
    </div>
  );
};

export default ConstructionMessage;
