import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Contact = () => {
  const emailAddress = 'langchenmusic@gmail.com'; // Replace with your desired email address

  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="text-center">
          <h1 className='tmp-heading'>This is still under construction...</h1>
          <p className='container mt-5 d-flex justify-content-center center tmp-text'>
            If you want to get in touch with me, please contact me through{' '}
            <a href={`mailto:${emailAddress}`} className='tmp-link'>
              {emailAddress}
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;