import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Music = () => {
  return (
    <>
      <Header />
      <div className="text-center">
        <h1 className='tmp-heading'>Under Construction...</h1>
        <p className='container mt-5 d-flex justify-content-center center tmp-text'> 
        For now, you can listen to a few of my music on my SoundCloud:{' '}
            <a href={'https://soundcloud.com/langchen'} className='tmp-link'>
              {'Lang Chen - SoundCloud'}
            </a>
          </p>
      </div>
      <Footer />
    </>
  );
};

export default Music;
