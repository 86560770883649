import React from 'react';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Music from './components/pages/Music';
import Mtjlc from './components/pages/Mtjlc';
import Codes from './components/pages/Codes';
import Events from './components/pages/Events';
import Contact from './components/pages/Contact';
import ConstructionMessage from './components/ConstructionMessage.js'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

const App = () => (
  <>
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/music" element={<Music />} />
      <Route path="/mtjlc" element={<Mtjlc />} />
      <Route path="/Codes" element={<Codes />} />
      <Route path="/events" element={<Events />} />
      <Route path="/contact" element={<Contact />} />
      {/* Add other routes here if needed */}
    </Routes>
  </Router>
  <ConstructionMessage />
  </>
);

export default App;
