import React from 'react';
import '../css/mainContentStyles.css'; // Import the external stylesheet
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components

const MainContent = () => {
  const quote = "What the f**k am I doing with my life?";
  const author = "lang chen";

  return (
    <Container>
      <Row className="justify-content-center align-items-center vh-90">
        <Col xs={12} sm={10} md={8} lg={6}>
          <div className="center-content"> {/* Add this wrapper div */}
            <GreenPanel />
            <PhotoSection />
            <QuotationSection quote={quote} author={author} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const GreenPanel = () => (
  <div className="green-panel">
    <h1>lang chen</h1>
    <p>music composer | music researcher | tech enthusiast</p>
  </div>
);

const PhotoSection = () => (
  <section className="photo text-center">
    <img src="images/3D-Avatar (sunglasses).png" alt="Lang Chen" />
  </section>
);

const QuotationSection = ({ quote, author }) => (
  <div className="row justify-content-center">
    <div className="col-md-10 col-lg-12">
      <div className="quotation text-center">
        <p className="quote">"{quote}"</p>
        <p className='author'>- {author}</p>
      </div>
    </div>
  </div>
);

export default MainContent;
