import React from 'react';
import '../css/Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="bg-no text-dark py-md-4 text-center custom-font">
      {/* Copyright and design statement */}
      <p className="my-2">&copy; {new Date().getFullYear()} All Rights Reserved | designed and created by lang chen</p>
    </footer>
  );
};

export default Footer;
