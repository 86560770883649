import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import '../../css/Tmp.css'

const Events = () => {
  return (
    <>
      <Header />
      <div className="text-center">
        <h1 className='tmp-heading'>Under Construction...</h1>
        <p className='container mt-5 d-flex justify-content-center center tmp-text'>
        Well, I am going to Darmstadt soon.<br/> 
        In fact, I am going to finish my website after returning from Darmstadt (and the following traveling with Elisa)...</p>
      </div>
      <Footer />
    </>
  );
};

export default Events;
