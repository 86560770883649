import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import '../../css/Tmp.css'

const About = () => {
  return (
    <>
      <Header />
      <div className="text-center">
        <h1 className='tmp-heading'>Who am I...</h1>
        <p className='container mt-5 d-flex justify-content-center center tmp-text'>
          Well, I will tell you later...</p>
      </div>
      <Footer />
    </>
  );
};

export default About;
