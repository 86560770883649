import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Mtjlc = () => {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="text-center">
          <h1 className='tmp-heading'>This is still under construction...</h1>
          <p className='container mt-5 d-flex justify-content-center center tmp-text'>
            mtjLC, which stands for "more than just Lion Crap," is a very cool new music project.<br/>
            It's a new music project very personal to myself, which I will tell you about later...
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Mtjlc;
