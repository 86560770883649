import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import '../../css/Tmp.css'

const Codes = () => {
  return (
    <>
      <Header />
      <div className="text-center">
        <h1 className='tmp-heading'>Under Construction...</h1>
      </div>
      <Footer />
    </>
  );
};

export default Codes;
